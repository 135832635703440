import { RefCallback } from 'react'
import { UseFormRegisterReturn } from 'react-hook-form'

export const withMaskitoRegister = (registerResult: UseFormRegisterReturn, maskitoRef: RefCallback<HTMLElement | null>): UseFormRegisterReturn & { onInput: UseFormRegisterReturn['onChange'] } => {
  const ref = (node: HTMLElement) => {
    registerResult.ref(node)
    maskitoRef(node)
  }

  return { ...registerResult, ref, onInput: registerResult.onChange }
}
