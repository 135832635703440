import React, { FC, useEffect } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import "./Partners.scss";
import './PartnersReg.scss'

interface IForm {
  surname: string;
  name: string;
  secondname: string;
  phone: string;
  inn: string;
  organization: string;
  site: string;
  password: string;
  confirmPassword: string;
  email: string;
}

const useRegForm = () => {
  const {
    register,
    formState: { errors },
    handleSubmit
  } = useForm<IForm>({
    defaultValues: {
      surname: "",
      name: "",
      secondname: "",
      phone: "",
      inn: "",
      organization: "",
      site: "",
      password: "",
      confirmPassword: "",
      email: ""
    }
  });
  return { register, errors, handleSubmit };
};


const PartnersReg: FC = () => {
  useEffect(() => {
    document.title = "Партнер Reforce - регистрация";
  });
  const { register, errors, handleSubmit } = useRegForm();

  const onSubmitHandler = (data: IForm) => {
    const rawPhone = data.phone
      .split(" ")
      .join("")
      .split("-")
      .join("")
      .replace("+7", "");
    console.log({ ...data, phone: rawPhone } as IForm);
  };

  return (
    <div className="wrapper wrapper--reg">
      <form className={"form form--reg"}
            onSubmit={(e) => e.preventDefault()}
      >
        <div className="top">
          <div className="logo__title">Reforce</div>
          <h1 className="title">Добро пожаловать!</h1>
        </div>
        <div className="content">
          <div className="fieldset">
            <div className="input">
              <label htmlFor="surname" className="input__label">Фамилия</label>
              <input
                className="login-form__input"
                type="text"
                id="surname"
                placeholder="Фамилия"
                {...register("surname", {
                  required: true
                })}
              />
            </div>
            <div className="input">
              <label htmlFor="name" className="input__label">Имя</label>
              <input
                className="login-form__input"
                type="text"
                id="name"
                placeholder="Имя"
                {...register("name", {
                  required: true
                })}
              />
            </div>
            <div className="input">
              <label htmlFor="secondname" className="input__label">Отчество</label>
              <input
                className="login-form__input"
                type="text"
                id="secondname"
                placeholder="Отчество"
                {...register("secondname", {
                  required: true
                })}
              />
            </div>
            <div className="input">
              <label htmlFor="phone" className="input__label">Телефон</label>
              <input
                className="login-form__input"
                type="tel"
                id="phone"
                placeholder="+7 999 123-00-00"
                autoComplete="tel-national"
                {...register("phone", {
                  required: true,
                  pattern: /^\s*(?:\+?(\d{1,3}))?([-. (]*(\d{3})[-. )]*)?((\d{3})[-. ]*(\d{2,4})(?:[-.x ]*(\d+))?)\s*$/gm
                })}
              />
            </div>
          </div>
          <div className="fieldset">
            <div className="input">
              <label htmlFor="inn" className="input__label">ИНН</label>
              <input
                className="login-form__input"
                type="text"
                id="inn"
                placeholder="1234567890"
                autoComplete="tel-national"
                {...register("inn", {
                  required: true,
                  pattern: /^(\d{10})$/
                })}
              />
            </div>
            <div className="input">
              <label htmlFor="organization" className="input__label">Наименование организации</label>
              <input
                className="login-form__input"
                type="text"
                id="organization"
                placeholder='ООО "Моя компания"'
                {...register("organization", {
                  required: true
                })}
              />
            </div>
            <div className="input">
              <label htmlFor="site" className="input__label">Сайт</label>
              <input
                className="login-form__input"
                type="text"
                id="site"
                placeholder="mycompany.ru"
                {...register("site", {
                  required: true
                })}
              />
            </div>
          </div>
          <div className="fieldset">
            <div className="input">
              <label htmlFor="password" className="input__label">Придумайте пароль</label>
              <input
                className="login-form__input"
                type="password"
                id="password"
                placeholder="******"
                {...register("password", {
                  required: true
                })}
              />
            </div>
            <div className="input">
              <label htmlFor="confirmPassword" className="input__label">Подтвердите пароль</label>
              <input
                className="login-form__input"
                type="password"
                id="confirmPassword"
                placeholder="******"
                {...register("confirmPassword", {
                  required: true
                })}
              />
            </div>
          </div>
          <div className="fieldset">
            <div className="input">
              <label htmlFor="email" className="input__label">Email</label>
              <input
                className="login-form__input"
                type="email"
                id="email"
                placeholder="myemail@mail.ru"
                {...register("email", {
                  required: true,
                  pattern: /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i
                })}
              />
            </div>
          </div>
        </div>
        <div className="bottom">
          <button type="submit" className="button">
            Регистрация
          </button>
          <Link to="/partners" className="link">
            Войти в аккаунт
          </Link>
        </div>
      </form>
    </div>
  );
};

export default PartnersReg;
