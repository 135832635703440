import { FC, useState } from "react";
import "./Rating.scss";

const Rating: FC<{ value: number, readOnly?: boolean, className?: string }> = ({ value, readOnly, className }) => {
  const [hoverRating, setHoverRating] = useState(0);

  const handleMouseEnter = (index: number) => {
    if (!readOnly) setHoverRating(index);
  };

  const handleMouseLeave = () => {
    if (!readOnly) setHoverRating(0);
  };

  const stars = [];

  for (let i = 1; i <= 5; i++) {
    let starType = "#DFE1DF";
    if (i <= value || i <= hoverRating) {
      starType = "#EF373E";
    }

    stars.push(
      <span key={i} onMouseEnter={() => handleMouseEnter(i)}
            onMouseLeave={handleMouseLeave}>
      <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="mask0_518_3740" maskUnits="userSpaceOnUse" x="0" y="0" width="25" height="25">
          <path
            d="M11.77 3.24493C12.1368 2.50169 13.1966 2.50169 13.5635 3.24493L15.6669 7.50691C15.8125 7.80206 16.0941 8.00663 16.4198 8.05395L21.1232 8.73739C21.9434 8.85658 22.2709 9.86455 21.6774 10.4431L18.274 13.7606C18.0383 13.9903 17.9308 14.3213 17.9864 14.6457L18.7898 19.3301C18.9299 20.147 18.0725 20.7699 17.3389 20.3843L13.1321 18.1726C12.8407 18.0194 12.4927 18.0194 12.2014 18.1726L7.99455 20.3843C7.26093 20.7699 6.40349 20.147 6.5436 19.3301L7.34704 14.6457C7.40267 14.3213 7.29513 13.9903 7.05944 13.7606L3.65605 10.4431C3.06253 9.86455 3.39004 8.85658 4.21026 8.73739L8.91363 8.05395C9.23934 8.00663 9.52091 7.80206 9.66657 7.50691L11.77 3.24493Z"
            fill="white" />
        </mask>
        <g mask="url(#mask0_518_3740)">
          <rect x="0.666718" y="0.427734" width="24" height="24" fill={starType} />
        </g>
      </svg></span>
    );
  }
  return (<div className={className ? "star-rating " + className : "star-rating"}>
    {stars}
  </div>);
};

export default Rating;
