import React from "react";
import { Route, Routes } from "react-router-dom";
import Layout from "./Layout";
import Nopage from "./pages/404/Nopage";
import Home from "./pages/Home/Home";
import Login from "./pages/Login/Login";
import Partners from "./pages/Partners/Partners";
import PartnersReg from "./pages/Partners/PartnersReg";
import Career from "./pages/Career/Career";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";

function App(): JSX.Element {
  return (
    <Routes>
      <Route path={"/"} element={<Layout />}>
        <Route index element={<Home />} />
        <Route path={"career"} element={<Career />} />
        <Route path={"privacy"} element={<PrivacyPolicy />} />
      </Route>
      <Route path={"login"} element={<Login />} />
      <Route path={"partners"} element={<Partners />} />
      <Route path={"partners/reg"} element={<PartnersReg />} />
      <Route path={"*"} element={<Nopage />} />
    </Routes>
  );
}

export default App;
