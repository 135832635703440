import { Link, NavLink } from "react-router-dom";
import "./Header.scss";
import React, { useEffect, useState } from "react";

export default function Header(): JSX.Element {
  const [menuOpen, setMenuOpen] = useState(false);
  const [size, setSize] = useState({
    width: 0,
    height: 0
  });
  useEffect(() => {
    const handleResize = () => {
      setSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    };
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (size.width > 864 && menuOpen) {
      setMenuOpen(false);
    }
  }, [size.width, menuOpen]);

  const menuToggleHandler = () => {
    setMenuOpen((p) => !p);
  };

  const anchorHandler = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    setMenuOpen(false);
    const target = e.target as HTMLAnchorElement;
    const href = target.getAttribute("href");
    if (href)
      document.querySelector(href)!.scrollIntoView({
        behavior: "smooth"
      });
  };

  return (
    <div className={menuOpen ? "header header--open" : "header"}>
      <Link className="logo" to={"/"}>
        <div className="logo__title">
          Reforce
        </div>
        <div className="logo__subtitle">технологии безопасности</div>
      </Link>
      <nav
        className={`header__nav ${(size.width <= 864) && menuOpen ? "header__nav--open" : ""}`}>
        <NavLink className={({ isActive }) =>
          isActive ? "tab tab--active" : "tab"
        } to="/">Клиентам</NavLink>
        <a className="tab" href={"https://reforce.group/partners"} target={"_blank"}>Партнерам</a>
        <NavLink className={({ isActive }) =>
          isActive ? "tab tab--active" : "tab"
        } to="/career">Карьера</NavLink>
        <a className={menuOpen ? "hidden" : "tab"} href="tel:88005557555" onClick={() => setMenuOpen(false)}>8 800 555-75-55</a>
        <a href={"https://app.reforce.group/"} target={"_blank"} title={"Вход в личный кабинет"}
           className={menuOpen ? "tab tab--lk" : "pseudo-btn"}>Личный
          кабинет
        </a>
        <div className={menuOpen ? "contacts" : "hidden"}>
          {/*<div className="contacts__item location">г. Екатеринбург, пер. Автоматики, стр. 1, помещ. 11, офис 213*/}
          {/*</div>*/}
          <div className="contacts__item phone"><a href="tel:88005557555" onClick={() => setMenuOpen(false)}>8 800 555-75-55</a></div>
          <div className="contacts__item footer__item--last mail"><a
            href="mailto:info@reforce.group" onClick={() => setMenuOpen(false)}>info@reforce.group</a></div>
        </div>
      </nav>
      <button onClick={menuToggleHandler} title={menuOpen ? "Закрыть меню" : "Открыть меню"}
              className={`menu-toggle ${menuOpen ? "menu-toggle--open" : ""}`}></button>
    </div>
  );
}
