import { ChangeEvent, FC, MouseEvent, DragEvent, useEffect, useState } from "react";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper";
import axios from "axios";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./Career.scss";
import Dialog from "../../components/Dialog/Dialog";
import { useMaskito } from "@maskito/react";
import options from "../../shared/mask";
import { withMaskitoRegister } from "../../shared/withMaskitoRegister";
import Footer from "../../components/Footer/Footer";
import Accordion from "../../components/Accordion";
import Rating from "../../components/Rating/Rating";

const Career: FC = () => {
  const [service, setService] = useState("");
  const [file, setFile] = useState<File | null>(null);
  const [formData, setFormData] = useState<FormData>(new FormData());
  const { register, handleSubmit, formState: { isValid } } = useForm();
  const maskedInputRef = useMaskito({ options });

  const handleFileSelect = (file: File) => {
    setFile(file);
    formData.set("file", file);
    setFormData(formData);
  };

  const handleDrop = (e: DragEvent<HTMLLabelElement>) => {
    e.preventDefault();
    if (e.dataTransfer.files) {
      handleFileSelect(e.dataTransfer.files[0]);
    }
  };

  const handleDragOver = (e: DragEvent<HTMLLabelElement>) => {
    e.preventDefault();
  };

  const handleFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      handleFileSelect(event.target.files[0]);
    }
  };

  const onSubmit: SubmitHandler<FieldValues> = async (data: FieldValues) => {
    const { email, name, phone } = data;
    formData.set("email", email);
    formData.set("name", `${name} ${service}`);
    formData.set("phone", phone);
    formData.set("client_type", "manager");
    formData.set("surname", "соискатель");

    try {
      const response = await axios.post("https://sdevicemanager.com/api/v1/landing/send_email", formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      });
      console.log(response);
      setIsModalOpen(true);
    } catch (error) {
      console.log(error);
    }
  };
  const anchorHandler = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    const target = e.target as HTMLAnchorElement;
    const href = target.getAttribute("href");
    if (href) {
      document.querySelector(href)?.scrollIntoView({
        behavior: "smooth"
      });
    }
  };

  const [size, setSize] = useState({
    width: 1000
  });

  useEffect(() => {
    const handleResize = () => {
      setSize({
        width: window.innerWidth
      });
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const advantages = [
    {
      color: "orange",
      icon: "house",
      title: "90%",
      text: "Сотрудников рекомендуют работу в нашей компании"
    }, {
      color: "green",
      icon: "map",
      title: "№1",
      text: "Среди конкурентов по уровню IT-экспертизы"
    }, {
      color: "blue",
      icon: "car",
      title: "50+",
      text: "Профессионалов, которые создают экосистему безопасности"
    }, {
      color: "orange",
      icon: "people",
      title: "100+",
      text: "Населённых пунктов, в которых доступны наши услуги"
    }, {
      color: "green",
      icon: "shield",
      title: "15",
      text: "Инновационных проектов, позволяющих нам быть лидерами в отрасли"
    }, {
      color: "blue",
      icon: "partner",
      title: "40 000",
      text: "Клиентов доверили нам свою безопасность"
    }
  ];

  const activities = [
    {
      img: "management",
      title: "Топ-менеджмент",
      text: "Руководители функциональных направлений, таких как HR, Маркетинг, Финансы, Развитие"
    },
    {
      img: "marketing",
      title: "Маркетинг",
      text: "Специалисты по маркетингу и рекламе, Digital, SMM и PR, аналитика."
    },
    {
      img: "sales",
      title: "Продажи",
      text: "Специалисты по продажам в сегментах b2c, b2b, b2g"
    },
    {
      img: "clients",
      title: "Работа с клиентами",
      text: "Клиентские менеджеры и другие специалисты, обрабатывающие обращения клиентов."
    },
    {
      img: "hr",
      title: "HR",
      text: "Специалисты по управлению персоналом, которые отвечают за подбор, обучение и развитие сотрудников."
    }
  ];

  const landmarks = [
    {
      id: "innovations",
      title: "Инновационность",
      text: "Мы стремимся создавать уникальные продукты, которые соответствуют самым современным требованиям безопасности. Мы постоянно ищем новые подходы и технологии, чтобы наши сервисы были максимально эффективными."
    },
    {
      id: "security",
      title: "Безопасность",
      text: "Мы ценим безопасность как наш основной принцип работы. Мы применяем самые строгие меры безопасности для защиты данных наших клиентов, а также гарантируем безопасность нашей собственной инфраструктуры и сетей. "
    },
    {
      id: "client",
      title: "Ориентация на клиента",
      text: "Мы полагаем, что для создания действительно удобных сервисов необходимо учитывать потребности и желания пользователей. Мы тесно сотрудничаем с нашими клиентами и партнерами, чтобы убедиться, что наши продукты максимально соответствуют их потребностям."
    }
  ];

  const about = ["Работа в крупной кроссрегиональной компании",
    "Профессиональная команда, состоящая из экспертов в своей области",
    "Уникальная возможность реализовать свой потенциал и развиваться вместе с компанией",
    "Комфортные условия работы и развитая корпоративная жизнь",
    "Конкурентная заработная плата и дополнительные бонусы"];

  const reviews = [
    {
      name: "Роман Владимирович",
      position: "Менеджер по продажам",
      title: "Профессиональная команда",
      text: "“Я работаю в компании уже две недели, и мне очень нравится моя работа. Все коллеги очень дружелюбные и готовы помочь в любой момент. Я чувствую, что я нахожусь в команде профессионалов, которые работают над чем-то важным и в тоже время интересным. Я уверен, что я могу добиться успеха в этой компании, и я рад, что я здесь работаю.”",
      rating: 5
    }, {
      name: "Анна Юрьевна",
      position: "Менеджер по работе с клиентами",
      title: "Общая миссия",
      text: "“Месяц назад я пришла в компанию, и мне нравится, что у нас есть хорошо отлаженный процесс работы. У нас есть четкие цели и сроки, и мы работаем согласно этим параметрам. Кроме того, у нас есть много возможностей для обучения и роста в компании. Я чувствую себя частью команды, которая создает что-то важное и полезное, и это очень мотивирует меня работать здесь.”",
      rating: 4
    }, {
      name: "Константин Викторович",
      position: "Маркетолог",
      title: "Точка роста",
      text: "“Я работаю с очень талантливыми и опытными людьми, которые всегда готовы помочь мне. У нас есть много интересных задач, которые помогают мне развиваться как специалисту. Я ощущаю себя важной частью команды и уверен, что я могу добиться успеха в этой компании. Я рад, что я здесь работаю и я бы рекомендовал эту компанию для всех, кто ищет работу в этой области.”",
      rating: 5
    }
  ];

  const faq = [
    {
      question: "Что делать, если мне не пришел отклик на заявку?",
      answer: "Все заявки рассматриваются в режиме очереди, возможны задержки при большом потоке резюме. Если нам понравится ваше резюме, то с вами свяжемся."
    },
    {
      question: "Какие навыки и квалификации необходимы для данной вакансии?",
      answer: " Мы ищем кандидатов с опытом работы в соответствующих направлениях и умением работать в команде. Также, наличие сертификатов и степеней в выбранной области будет являться преимуществом при рассмотрении заявки."
    },
    {
      question: "Какие проекты вы можете предложить для работы на этой вакансии?",
      answer: "Мы имеем широкий спектр проектов, от мелких внутренних задач до масштабных проектов с использованием передовых технологий. Мы сможем назначить вас на проект, который наилучшим образом соответствует вашим навыкам и интересам."
    },
    {
      question: "Какая компенсация и преимущества предлагаются компанией?",
      answer: " Мы предлагаем конкурентную заработную плату, гибкий график работы, возможность удаленной работы, медицинскую страховку, пенсионные накопления, оплачиваемые отпуска и больничные, оплата обучения и дополнительные бонусы в зависимости от результативности."
    },
    {
      question: "Какая организационная культура у вашей компании?",
      answer: "Мы ценим командную работу, открытость и честность в общении, уважение к мнению других, профессиональный рост и развитие сотрудников. Мы стремимся создать комфортную рабочую обстановку и поддерживать нашу команду в профессиональном и личностном развитии."
    }
  ];

  return (
    <>
      <div className="main-block main-block--career">
        <div className="main-block__text">
          <h1 className="title">Создадим безопасное завтра вместе - присоединяйтесь к команде</h1>
          <div className="button-wrapper">
            <a href={"#order"} className="button button--red" onClick={(e) => anchorHandler(e)}>Откликнуться</a>
          </div>
        </div>
      </div>

      <div className="advantages">
        {advantages.map(x => <div className="advantage" key={x.title}>
          <div className={"advantage__icon" + " " + x.color + " " + x.icon}></div>
          <p className="advantage__title">{x.title}</p>
          <p className="advantage__text">{x.text}</p>
        </div>)}
      </div>

      <div className="activities">
        <h3 className="heading">Направления</h3>
        {size.width < 1280 ? (
          <div>
            <Swiper
              pagination={{
                bulletActiveClass: "pagination-bullet--active",
                bulletClass: "pagination-bullet",
                clickable: true
              }}
              modules={[Autoplay, Pagination]}
              spaceBetween={32}
              // autoplay={{
              //   delay: 5000,
              //   disableOnInteraction: false
              // }}
              slidesPerView={"auto"}
              loop={true}
              grabCursor={true}
            >
              {activities.map(x => <SwiperSlide className="card" key={x.title}>
                <div className="card__content">
                  <p className="card__title">{x.title}</p>
                  <p className="card__text">{x.text}</p>
                  <a href={"#order"} className="button button--blue" onClick={() => setService(x.title)}>Откликнуться</a>
                </div>
                <div className={"card__img " + x.img}></div>
              </SwiperSlide>)}
            </Swiper>
          </div>
        ) : (
          <div className="activities__wrapper">
            {activities.map(x => <div className="card" key={x.title}>
              <div className="card__content">
                <p className="card__title">{x.title}</p>
                <p className="card__text">{x.text}</p>
                <a href={"#order"} className="button button--blue" onClick={() => setService(x.title)}>Откликнуться</a>
              </div>
              <div className={"card__img " + x.img}></div>
            </div>)}
          </div>)
        }
      </div>

      <div className="landmarks">
        <h3 className="heading">Наши ориентиры</h3>
        <div className="landmarks-section">
          {landmarks.map(item => (
            <div className="card" key={item.id}>
              <div className={"card__img " + item.id}></div>
              <div className="card__content">
                <p className="card__title">{item.title}</p>
                <p className="card__text">{item.text}</p>
              </div>
            </div>
          ))}

        </div>
      </div>


      <div className="about about--career" id={"about"}>
        <h3 className="about__title heading">Почему мы?</h3>
        <ol className="about__list">
          {about.map(text => (<li className="about__text" key={text}>{text}</li>))}
        </ol>
        <div className="about__img"></div>
      </div>

      <div className="proccess">
        <h3 className="heading">Процесс отбора</h3>
        <ol className="proccess__list">
          <li className="proccess__text">Выберите подходящую вакансию</li>
          <li className="proccess__text">Оставьте заявку, прикрепив резюме</li>
          <li className="proccess__text">Пройдите собеседование с HR</li>
          <li className="proccess__text">Получите предложение</li>
        </ol>
      </div>

      <div className="reviews">
        <div className="heading">Отзывы сотрудников</div>
        {/*<div className="reviews__section">*/}
        {/*  <div className="reviews__half">*/}
        {/*    <div className="reviews__title">Отзывы</div>*/}
        {/*    <div className="reviews__img"></div>*/}
        {/*  </div>*/}
        {/*  <div className="reviews__half">*/}
        {/*    <div className="reviews__num">4,9</div>*/}
        {/*    <div className="reviews__text">Рейтинг работодателя по оценке hh.ru*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</div>*/}
        {size.width < 1280 ? (<div>
          <Swiper
            pagination={{
              bulletActiveClass: "pagination-bullet--active",
              bulletClass: "pagination-bullet",
              clickable: true
            }}
            modules={[Autoplay, Pagination]}
            spaceBetween={32}
            // autoplay={{
            //   delay: 5000,
            //   disableOnInteraction: false
            // }}
            slidesPerView={"auto"}
            loop={true}
            grabCursor={true}
          >
            {reviews.map(x => <SwiperSlide className="review" key={x.name}>
              <div className="review__header">
                <div className="review__person">
                  <div className="review__name">{x.name}</div>
                  <div className="review__position">{x.position}</div>
                </div>
                <Rating className="review__rating" value={x.rating} readOnly />
              </div>
              <div className="review__body">
                <div className="review__title">{x.title}</div>
                <div className="review__text">{x.text}</div>
              </div>
            </SwiperSlide>)}
          </Swiper>
        </div>) : (<div className="reviews__slider">
          {reviews.map(x => (
            <div className="review" key={x.name}>
              <div className="review__header">
                <div className="review__person">
                  <div className="review__name">{x.name}</div>
                  <div className="review__position">{x.position}</div>
                </div>
                <Rating className="review__rating" value={x.rating} readOnly />
              </div>
              <div className="review__body">
                <div className="review__title">{x.title}</div>
                <div className="review__text">{x.text}</div>
              </div>
            </div>
          ))}
        </div>)}

      </div>

      {/*<div className="faq">*/}
      {/*  <h3 className="heading">Часто задаваемые вопросы</h3>*/}
      {/*  <div className="faq-section">*/}
      {/*    {faq.map((question, i) => (*/}
      {/*      <Accordion key={i} title={question.question} body={question.answer} />*/}
      {/*    ))}*/}
      {/*  </div>*/}
      {/*</div>*/}

      <div className="order order--career" id={"order"}>
        <h3 className="order__title">Оставьте заявку</h3>
        <form action="" className="order-form" onSubmit={handleSubmit(onSubmit)}>
          <input type="text" className="order-form__input" placeholder="Имя" {...register("name", {
            required: true,
            maxLength: 90
          })}
          />
          <input type="tel" className="order-form__input"
                 placeholder="Телефон" {...withMaskitoRegister(register("phone", {
            required: true
          }), maskedInputRef)}
          />
          <input type="email" className="order-form__input" placeholder="Почта" {...register("email", {
            required: true,
            pattern: /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i
          })}
          />
          <label className="file-input" onDragOver={handleDragOver} onDrop={handleDrop}>
            {file ? file.name : "Загрузите ваше резюме в формате PDF"}
            <input type="file" accept="image/*,.doc,.docx,.pdf" onChange={handleFileUpload}
                   hidden />
          </label>
          <button type="submit" disabled={!isValid} className="button button--red">Отправить</button>
        </form>
      </div>

      <Footer />

      <Dialog title="Заявка успешно отправлена!" isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}></Dialog>
    </>
  );
};

export default Career;
