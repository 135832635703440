import { FC, MouseEvent, useEffect, useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper";
import axios from "axios";
import store from "../../assets/images/app-store-badge.svg";
import play from "../../assets/images/google-play-badge.png";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./Home.scss";
import Dialog from "../../components/Dialog/Dialog";
import { useMaskito } from "@maskito/react";
import options from "../../shared/mask";
import { withMaskitoRegister } from "../../shared/withMaskitoRegister";
import Footer from "../../components/Footer/Footer";

const Home: FC = () => {
  const [service, setService] = useState("");
  const { register, handleSubmit, formState: { isValid } } = useForm();
  const maskedInputRef = useMaskito({ options });
  const onSubmit = (data: FieldValues) => {
    const { name, phone } = data;
    const formData = new FormData();
    formData.set('name', name)
    formData.set('phone', phone)
    formData.set('email', service|| 'none')
    formData.set('surname', "клиент")
    formData.set('client_type', "client")
    axios.post("https://sdevicemanager.com/api/v1/landing/send_email", formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    })
      .then(response => {
        console.log(response);
        setIsModalOpen(true);
      })
      .catch(error => console.log(error));
  };
  const anchorHandler = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    const target = e.target as HTMLAnchorElement;
    const href = target.getAttribute("href");
    if (href) {
      document.querySelector(href)!.scrollIntoView({
        behavior: "smooth"
      });
    }
  };

  const [size, setSize] = useState({
    width: 1000
  });

  useEffect(() => {
    setSize({
      width: window.innerWidth
    });
    const handleResize = () => {
      setSize({
        width: window.innerWidth
      });
    };
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [activeTab, setActiveTab] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const advantages = [
    {
      color: "orange",
      icon: "house",
      title: "70000+",
      text: "Объектов под мониторингом"
    }, {
      color: "green",
      icon: "map",
      title: "100+",
      text: "Городов присутствия"
    }, {
      color: "blue",
      icon: "car",
      title: "250+",
      text: "Экипажей под управлением"
    }, {
      color: "orange",
      icon: "people",
      title: "3000+",
      text: "Сотрудников под контролем"
    }, {
      color: "green",
      icon: "shield",
      title: "30+",
      text: "Сервисов безопасности"
    }, {
      color: "blue",
      icon: "partner",
      title: "120+",
      text: "Партнёров на платформе"
    }
  ];

  const opportunities = [
    [{
      img: "flat",
      title: "Что происходит дома",
      text: "Получите уведомление, если кто-то проникнет в дом"
    }, {
      img: "alarm",
      title: "Вызов экстренной помощи",
      text: "Вызовите охрану в два клика"
    }, {
      img: "children",
      title: "Ребёнок под контролем",
      text: "Приложение уведомит, когда ребёнок вернётся со школы"
    }, {
      img: "pets",
      title: "Присматривайте за животными",
      text: "Узнайте, чем занимается ваш домашний питомец"
    }, {
      img: "repair",
      title: "Следите за ходом ремонта",
      text: "Смотрите, что делают рабочие, онлайн"
    },
      {
        img: "laundry",
        title: "Предотвратите потоп",
        text: "Получите уведомление о протечке посудомойки или стиральной машины"
      }],
    [
      {
        img: "work",
        title: "Будьте в курсе событий",
        text: "Контролируйте бизнес-процессы, находясь в любой точке мира"
      }, {
      img: "control",
      title: "Обеспечьте дисциплину",
      text: "Получайте уведомления об опозданиях и ранних уходах сотрудников"
    }, {
      img: "service",
      title: "Повышайте качество обслуживания",
      text: "Следите за действиями и разговорами персонала с клиентами"
    }, {
      img: "cheat",
      title: "Защититесь от мошенничества",
      text: "Имейте объективные видеодоказательства действий сотрудников или клиентов"
    }, {
      img: "suspected",
      title: "Защитите себя и людей",
      text: "Незаметно вызовите помощь в случае угрозы нападения"
    }
    ],
    [{
      img: "success",
      title: "Упростите работу с ЧОПами",
      text: "Работа через одного поставщика и персонального менеджера"
    }, {
      img: "cafe",
      title: "Стандартизируйте процессы",
      text: "Единые стандарты качества по всем вашим точкам"
    }, {
      img: "charts",
      title: "Контролируйте качество услуг",
      text: "Прозрачная отчётность и статистика KPI охраны"
    }, {
      img: "network",
      title: "Сэкономьте бюджет",
      text: "Комплексные решения, позволяющие снизить человеческий фактор и повысить эффективность"
    }
    ]
  ];

  const services = [
    {
      img: "monitor",
      title: "Мониторинг 24/7",
      text: "Круглосуточный мониторинг состояния вашего объекта, сервисное обслуживание, техническая поддержка, уведомление о событиях и экстренный вызов служб реагирования.",
      price: 2900,
      fee: 600
    },
    {
      img: "camera",
      title: "Видеообнаружение",
      text: "Онлайн просмотр и защищённое хранение видео, уведомления о появления людей и событий на камерах видеонаблюдения через мобильное приложение с возможностью вызова службы реагирования.",
      price: 3500,
      fee: 210
    }, {
      img: "alert",
      title: "Экстренный вызов ",
      text: "Оперативное прибытие вооруженного экипажа группы быстрого реагирования, который проведёт осмотр объекта, задержание правонарушителей и фиксацию следов преступления.Тариф - от 1500 р/мес.",
      price: 0,
      fee: 1500
    }, {
      img: "person",
      title: "Кнопка жизни",
      text: "Имейте возможность вызова помощи и уведомление родственников и экстренных служб, если почувствуете себя плохо.",
      price: 0,
      fee: 300
    }, {
      img: "shield",
      title: "Контроль персонала",
      text: "Анализ присутствия на рабочем месте, использования СИЗ, контроль за бизнес-процессами и соблюдение режима работы.",
      price: 0,
      fee: 3000
    }, {
      img: "house",
      title: "Умный дом",
      text: "Контроль и управление климатом, удобные сценарии автоматизации работы оборудования.",
      price: 9900,
      fee: 99
    }
  ];

  const appAdvantage = [
    {
      title: "Контролируйте",
      text: "Список ваших объектов с ключевой информацией"
    }, {
      title: "Управляйте",
      text: "Вашим объектом и получайте актуальные данные о нём"
    }, {
      title: "Отслеживайте",
      text: "События, произошедшие на объекте"
    }, {
      title: "Пополняйте",
      text: "Баланс, просматривайте историю платежей"
    }, {
      title: "Получайте",
      text: "Поддержку онлайн удобным для вас способом"
    }
  ];

  return (
    <>
      <div className="main-block">
        <div className="main-block__text">
          <h1 className="title">Удобные и технологичные сервисы для <span className="accent">безопасности</span></h1>
          <p className="subtitle">Избавьтесь от переживаний по поводу дома, близких и бизнеса, чтобы сконцентрироваться
            на главном</p>
          <div className="button-wrapper">
            <a href={"#order"} className="button button--red" onClick={(e) => anchorHandler(e)}>Оставить заявку</a>
          </div>
        </div>
      </div>

      <div className="advantages">
        {advantages.map(x => <div className="advantage" key={x.title}>
          <div className={"advantage__icon" + " " + x.color + " " + x.icon}></div>
          <p className="advantage__title">{x.title}</p>
          <p className="advantage__text">{x.text}</p>
        </div>)}
      </div>

      <div className="opportunity" id={"forclients"}>
        <h3 className="heading">Возможности клиентов</h3>
        <div className="tabs">
          <div className="button-wrapper">
            <button className={`button ${0 === activeTab ? "button--black" : "button--white"}`} type="button"
                    onClick={() => setActiveTab(0)}>физическим лицам
            </button>
            <button className={`button ${1 === activeTab ? "button--black" : "button--white"}`} type="button"
                    onClick={() => setActiveTab(1)}>бизнесу
            </button>
            <button className={`button ${2 === activeTab ? "button--black" : "button--white"}`} type="button"
                    onClick={() => setActiveTab(2)}>корпоративным клиентам
            </button>
          </div>
        </div>
        {size.width < 1024 ? (
          <div>
            <Swiper
              pagination={{
                bulletActiveClass: "pagination-bullet--active",
                bulletClass: "pagination-bullet",
                clickable: true
              }}
              modules={[Autoplay, Pagination]}
              spaceBetween={32}
              autoplay={{
                delay: 5000,
                disableOnInteraction: false
              }}
              slidesPerView={"auto"}
              loop={true}
              grabCursor={true}
            >
              {opportunities[activeTab].map(x => <SwiperSlide className="card" key={x.title}>
                <div className={"card__img " + x.img}></div>
                <div className="card__content">
                  <p className="card__title">{x.title}</p>
                  <p className="card__text">{x.text}</p>
                </div>
              </SwiperSlide>)}
            </Swiper>
          </div>
        ) : (
          <div className={`cards ${2 === activeTab ? "cards--four" : ""}`}>
            {opportunities[activeTab].map(x => <div className="card" key={x.title}>
              <div className={"card__img " + x.img}></div>
              <div className="card__content">
                <p className="card__title">{x.title}</p>
                <p className="card__text">{x.text}</p>
              </div>
            </div>)}
          </div>)
        }
      </div>

      <div className="services" id={"services"}>
        <h3 className="heading">Актуальные услуги</h3>
        <div><Swiper
          pagination={{
            bulletActiveClass: "pagination-bullet--active",
            bulletClass: "pagination-bullet",
            clickable: true
          }}
          modules={[Autoplay, Pagination, Navigation]}
          navigation={true}
          spaceBetween={32}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false
          }}
          slidesPerView={"auto"}
          loop={true}
          grabCursor={true}
        >
          {services.map(x => <SwiperSlide className="card" key={x.title}>

            <div className={"card__img " + x.img}></div>
            <div className="card__content">
              <p className="card__title">{x.title}</p>
              <p className="card__text">{x.text}</p>
              <div className="card__price">
                <span>Подключение</span><b>{x.price ? "от " + x.price + " руб" : "бесплатно"}</b></div>
              <div className="card__price"><span>Абон. плата</span><b>от {x.fee} руб/мес</b></div>
              <a href={"#order"} className="button button--grey" onClick={() => setService(x.title)}>Оставить заявку</a>
            </div>

          </SwiperSlide>)}
        </Swiper></div>
      </div>

      <div className="app">
        <h3 className="heading">Безопасность <span className="accent">в ваших руках</span></h3>
        <div className="app-wrapper">
          <div className="app-img"></div>
          <div className="actions">
            {appAdvantage.map(x => <div className="item" key={x.title}>
              <div className="item__icon"></div>
              <div className="item__content">
                <p className="item__title">{x.title}</p>
                <p className="item__text">{x.text}</p>
              </div>
            </div>)}
            <div className="item">
              <img src={store} alt="app store" className="store-badge" />
              <img src={play} alt="google play" className="market-badge" />
            </div>
          </div>
        </div>
      </div>

      <div className="mission">
        <div className="mission__wrapper">
          <div className="mission__heading">Миссия</div>
          <div className="mission__text">Повышая эффективность услуг безопасности,
            мы защищаем комфортные условия жизни и развития бизнеса.
          </div>
        </div>
      </div>

      <div className="about" id={"about"}>
        <h4 className="about__title">Рефорс - технологическая компания, платформа усиления безопасности.</h4>
        <ol className="about__list">
          <li className="about__text">Интегрированные системы безопасности: мониторинг, автоматизация,
            видеоаналитика.
          </li>
          <li className="about__text">Работаем с лидирующими компаниями России.</li>
          <li className="about__text">Современные системы контроля качества работы охранных предприятий, позволяющие в
            разы повысить эффективность и качество
          </li>
        </ol>
        <div className="about__img"></div>
      </div>

      <div className="order" id={"order"}>
        <h3 className="order__title">Оставьте заявку на <span className="accent">бесплатную</span> консультацию</h3>
        <form action="" className="order-form" onSubmit={handleSubmit(onSubmit)}>
          <input type="text" className="order-form__input" placeholder="Имя" {...register("name", {
            required: true,
            maxLength: 90
          })}
          />
          <input type="tel" className="order-form__input"
                 placeholder="Телефон" {...withMaskitoRegister(register("phone", {
            required: true
          }), maskedInputRef)}
          />
          <button type="submit" disabled={!isValid} className="button button--red">Отправить</button>
        </form>
      </div>

      <Footer />

      <Dialog title="Заявка успешно отправлена!" isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}></Dialog>
    </>
  );
};

export default Home;
