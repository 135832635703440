import React, { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./Partners.scss";

const Partners: FC = () => {
  useEffect(() => {
    document.title = "Партнер Reforce - вход";
  });

  const [phone, setPhone] = useState<string>("");

  return (
    <div className="wrapper">
      <form className={phone === "" ? "form" : "form form--phone"}
            onSubmit={(e) => e.preventDefault()}
      >
        <div className="top">
          <div className="logo__title">Reforce</div>

          <h1 className="title">
            {phone === "" ? "Добро пожаловать!" : "Введите код"}
          </h1>
          {phone !== "" && (
            <p className="send">
              Код выслан на номер <b>{phone}</b>
            </p>
          )}
        </div>
        <div className="content">
          <div className="input">
            <label htmlFor="phone" className="input__label">Телефон</label>
            <input
              className="login-form__input"
              type="tel"
              id="phone"
              placeholder="+7 999 123-00-00"
              autoComplete="tel-national"
              required
            />
          </div>
        </div>
        <div className="bottom">
          <button type="submit" className="button">
            Войти
          </button>
          <Link to="reg" className="link">
            У меня еще нет аккаунта
          </Link>
        </div>
      </form>
    </div>
  );
};

export default Partners;
