import React from "react";
import "./PrivacyPolicy.scss"
import Footer from "../../components/Footer/Footer";

const PrivacyPolicy = () => {
  return (
    <>
      <main className="privacy-policy">
        <h1 className="privacy-policy__title">Политика в отношении обработки персональных данных</h1>
        <p className="privacy-policy__text">
          <strong>Абонент –</strong> физическое или юридическое лицо, пользующееся
          услугами связи, предоставляемыми Оператором на основании Договора на
          оказание услуг связи.
        </p>
        <p className="privacy-policy__text">
          <strong>Обезличивание персональных данных – </strong> действия, в
          результате которых становится невозможным без использования
          дополнительной информации определить принадлежность персональных данных
          конкретному субъекту персональных данных.
        </p>
        <p className="privacy-policy__text">
          <strong>Обработка персональных данных – </strong> любое действие
          (операция) или совокупность действий (операций), совершаемых с
          использованием средств автоматизации или без использования таких средств
          с персональными данными, включая сбор, запись, систематизацию,
          накопление, хранение, уточнение (обновление, изменение), извлечение,
          использование, передачу (распространение, предоставление, доступ),
          обезличивание, блокирование, удаление, уничтожение персональных данных.
        </p>
        <p className="privacy-policy__text">
          <strong>Общество – </strong> любое действие
          юридическое лицо, самостоятельно или совместно с другими лицами
          организующие и (или) осуществляющие обработку персональных данных, а
          также определяющие цели обработки персональных данных, состав
          персональных данных, подлежащих обработке, действия (операции),
          совершаемые с персональными данными.
        </p>
        <p className="privacy-policy__text">
          <strong>Персональные данные – </strong> информация, необходимая Оператору в связи с исполнением
          обязательств по Договору на оказание услуг связи, касающаяся конкретного субъекта
          персональных данных.
        </p>
        <p className="privacy-policy__text">
          <strong>Уничтожение персональных данных – </strong> действия, в
          результате которых становится невозможным восстановить содержание
          персональных данных в информационной системе персональных данных и (или)
          в результате которых уничтожаются материальные носители персональных
          данных.<br />
          Общество обязано опубликовать или иным образом обеспечить неограниченный
          доступ к настоящей Политике обработки персональных данных в соответствии
          с ч. 2 СТ. 18.1. ФЗ152.
        </p>
        <ol className="subsequence">
          <li className="privacy-policy__subtitle">
            Общие положения
            <ol className="subsequence">
              <li className="privacy-policy__text">
                Настоящее Положение «Политика в отношении обработки персональных
                данных» (далее – «Положение») издано и применяется Оператором
                обработки персональных данных (далее – «Общество»), в соответствии
                с пп. 2 ч. 1 ст. 18.1 Федерального закона от 27.07.2006 N 152- ФЗ
                «О персональных данных».), и является основополагающим внутренним
                регулятивным документом Общества, определяющим ключевые
                направления его деятельности в области обработки и защиты
                персональных данных.
              </li>
              <li className="privacy-policy__text">
                Под сбором персональных данных понимается целенаправленный процесс
                получения персональных данных Обществом непосредственно от
                Субъекта персональных данных либо через специально привлеченных
                для этого третьих лиц. В связи с чем, локализации подлежат только
                те персональные данные, которые были получены Обществом в
                результате осуществляемой им целенаправленной деятельности по
                организации сбора таких данных, а не в результате случайного
                (незапрошенного) попадания к нему персональных данных. <br />
                Случайное, ненамеренное получение, хранение и иные операции
                с персональными данными российских граждан не влекут обязанности
                локализовать обработку персональных данных в Обществе, в связи с
                чем, Общество не должно предпринимать каких-либо действий в
                отношении персональных данных, случайно к нему попавших. <br />
                В частности локализация не требуется в случае:
                <ol className="account">
                  В частности локализация не требуется в случае:
                  <li className="privacy-policy__text">
                    незапрашиваемого получения персональных данных, например,
                    произвольной (случайной) входящей корреспонденции и
                    электронных писем;
                  </li>
                  <li className="privacy-policy__text">
                    получения персональных данных поступивших в Общество от других
                    юридических лиц, если такие данные представляют собой
                    контактную информацию работников или представителей таких
                    юридических лиц, переданную в ходе осуществления ими своей
                    законной деятельности.
                  </li>
                </ol>
              </li>
              <li className="privacy-policy__text">
                Цель данного Положения – обеспечение требований защиты прав
                граждан при обработке персональных данных.
              </li>
              <li className="privacy-policy__text">
                Обработка персональных данных Абонентов Общества, объем, и
                содержание обрабатываемых персональных данных определяется в
                соответствии с Конституцией Российской Федерации, Федеральными
                законами от 27.07.2006 № 149-ФЗ «Об информации, информационных
                технологиях и о защите информации, от 27.07.2006 № 152-ФЗ «О
                персональных данных», другими федеральными законами и подзаконными
                актами.
              </li>
              <li className="privacy-policy__text">
                Настоящее Положение утверждается директором и вводится приказом по основной
                деятельности Общества. Настоящее Положение является обязательным для исполнения
                всеми сотрудниками Общества, имеющими доступ к персональным данным Абонентов.
              </li>
            </ol>
          </li>
          <li className="privacy-policy__subtitle">
            Цель обработки персональных данных
            <ol className="subsequence">
              <li className="privacy-policy__text">
                Цель обработки персональных данных - осуществление комплекса
                действий направленных на достижение цели, в том числе:
                <ul className="dash">
                  <li className="privacy-policy__text">
                    в целях исполнения договора на оказание услуг связи,
                  </li>
                  <li className="privacy-policy__text">
                    в целях оказания консультационных и информационных услуг,
                  </li>
                  <li className="privacy-policy__text">
                    в целях исполнения требований законодательства РФ.
                  </li>
                </ul>
              </li>
              <li className="privacy-policy__text">
                К персональным данным Абонентов, обрабатываемых Обществом
                относятся:
                <ul className="dash">
                  <li className="privacy-policy__text">Фамилия, имя, отчество.</li>
                  <li className="privacy-policy__text">Год рождения.</li>
                  <li className="privacy-policy__text">Месяц рождения.</li>
                  <li className="privacy-policy__text">Дата рождения.</li>
                  <li className="privacy-policy__text">Место рождения.</li>
                  <li className="privacy-policy__text">Паспортные данные.</li>
                  <li className="privacy-policy__text">Адрес электронной почты.</li>
                  <li className="privacy-policy__text">
                    Номер телефона (домашний, сотовый).
                  </li>
                  <li className="privacy-policy__text">
                    В Организации могут создаваться (создаются, собираются) и
                    хранятся следующие документы и сведения, в том числе в
                    электронном виде, содержащие данные об Абонентах:
                  </li>
                  <li className="privacy-policy__text">Заявление.</li>
                  <li className="privacy-policy__text">
                    Копии документов, удостоверяющих личность, а также иных
                    документов, предоставляемых Абонентом, и содержащих
                    персональные данные.
                  </li>
                  <li className="privacy-policy__text">
                    Данные по оплатам, содержащие платежные и иные реквизиты
                    Абонента. Абонентом, и содержащих персональные данные.
                  </li>
                  <li className="privacy-policy__text">
                    данные по оплатам, содержащие платежные и иные реквизиты
                    Абонента.
                  </li>
                </ul>
              </li>
              <li className="privacy-policy__text">
                Условием прекращения обработки персональных данных является
                расторжение договора на оказание услуг связи.
              </li>
            </ol>
          </li>
          <li className="privacy-policy__subtitle">
            Порядок сбора персональных данных
            <ol className="subsequence">
              <li className="privacy-policy__text">
                Физических лиц:
                <ol className="subsequence">
                  <li className="privacy-policy__text">
                    Общество получает персональные данные Абонентов у них самих с
                    их письменного согласия.
                  </li>
                  <li className="privacy-policy__text">
                    Согласие Абонента на использование его персональных данных
                    хранится у Оператора в бумажном и/или электронном виде.
                  </li>
                  <li className="privacy-policy__text">
                    Общество обязано сообщить Абоненту о целях обработки
                    персональных данных.
                  </li>
                  <li className="privacy-policy__text">
                    Заключение Договора на абонентское обслуживание невозможно без
                    предоставления Обществу Абонентом согласия на обработку его
                    персональных данных.
                  </li>
                </ol>
              </li>
            </ol>
          </li>
          <li className="privacy-policy__subtitle">
            Порядок обработки персональных данных
            <ol className="subsequence">
              <li className="privacy-policy__text">
                Получение, хранение, обработка, в том числе передача,
                распространение, использование, блокирование и уничтожение
                персональных данных субъектов персональных данных может,
                осуществляется исключительно в целях обеспечения соблюдения
                законов и иных нормативных правовых актов, исполнения договоров об
                оказании услуг связи, одной из сторон которых является Абонент.
              </li>
              <li className="privacy-policy__text">
                К обработке персональных данных Абонентов могут иметь доступ
                только сотрудники, допущенные к работе с персональными данными
                Абонентов и подписавшие Соглашение о неразглашении персональных
                данных Абонентов.
              </li>
              <li className="privacy-policy__text">
                Обработка персональных данных Абонента может осуществляться
                исключительно в целях, установленных Положением, соблюдения
                законов и иных нормативных правовых актов РФ.
              </li>
              <li className="privacy-policy__text">
                Общество имеет право без согласия Абонента осуществлять обработку
                его персональных данных в следующих случаях:
                <ul className="dash">
                  <li className="privacy-policy__text">
                    если обработка персональных данных осуществляется в целях
                    исполнения договора, одной из сторон которого является субъект
                    персональных данных – Абонент;
                  </li>
                  <li className="privacy-policy__text">
                    когда обработка персональных данных Абонента осуществляется
                    для статистических или иных научных целей при условии
                    обязательного обезличивания его персональных данных;
                  </li>
                  <li className="privacy-policy__text">
                    если обработка персональных данных Абонента необходима для
                    защиты жизни, здоровья или иных жизненно важных интересов
                    Абонента, если получение его согласия невозможно.
                  </li>
                  <li className="privacy-policy__text">
                    если необходима для достижения целей, предусмотренных законом,
                    для осуществления и выполнения возложенных законодательством
                    РФ на оператора функций, полномочий и обязанностей п.2 ч.
                    1 ст. 6 Закона № 152 ФЗ «О персональных данных».</li>
                </ul>
              </li>
            </ol>
          </li>
          <li className="privacy-policy__subtitle">
            Хранение персональных данных
            <ol className="subsequence">
              <li className="privacy-policy__text">
                Хранение персональных данных осуществляется в форме, позволяющей
                определить субъект персональных данных, не дольше, чем этого
                требуют цели обработки персональных данных, если срок хранения
                персональных данных не установлен федеральным законом или
                договором.
              </li>
              <li className="privacy-policy__text">
                Обрабатываемые персональные данные подлежат уничтожению либо
                обезличиванию по достижении целей обработки или в случае утраты
                необходимости в достижении этих целей.
              </li>
              <li className="privacy-policy__text">
                Персональные данные Абонентов могут храниться, как на бумажных
                носителях, так и в электронном виде.
              </li>
              <li className="privacy-policy__text">
                Персональные данные Абонентов на бумажных носителях должны
                храниться способом, исключающим несанкционированный доступ к ним
                лиц, не входящих в перечень сотрудников Общества, имеющих доступ к
                персональным данным.
              </li>
              <li className="privacy-policy__text">
                Персональные данные Абонентов в электронном виде хранятся в
                локальной компьютерной сети и вносятся в программный комплекс
                Общества.
              </li>
              <li className="privacy-policy__text">
                В обязанности сотрудников структурных подразделений,
                осуществляющих ведение базы данных, входит формирование и
                обеспечение сохранности сведений, содержащихся в базе данных
                Общества.
              </li>
              <li className="privacy-policy__text">
                Работники, допущенные к персональным данным, подписывают согласие
                – обязательство о неразглашении персональных данных (Приложение №1).
              </li>
            </ol>
          </li>
          <li className="privacy-policy__subtitle">
            Передача обработки персональных данных другому лицу
            <ol className="subsequence">
              <li className="privacy-policy__text">
                Общество вправе поручить обработку персональных данных другому
                лицу с согласия Субъекта персональных данных, на основании
                заключенного с этим лицом договора.
              </li>
              <li className="privacy-policy__text">
                Лицо, осуществляющее обработку персональных данных по поручению
                Общества, обязано соблюдать принципы и правила обработки
                персональных данных, предусмотренные настоящим Положением.
              </li>
              <li className="privacy-policy__text">
                Лицо, осуществляющее обработку персональных данных по поручению
                Общества, не обязано получать согласие Субъекта персональных
                данных на обработку его персональных данных.
              </li>
              <li className="privacy-policy__text">
                В случае если Общество поручает обработку персональных данных
                другому лицу, ответственность перед субъектом персональных
                данных за действия указанного лица несет Общество.
              </li>
              <li className="privacy-policy__text">
                Лицо, осуществляющее обработку персональных данных по поручению
                Общества, несет ответственность перед Обществом.
              </li>
            </ol>
          </li>
          <li className="privacy-policy__subtitle">
            Конфиденциальность
            <ol className="subsequence">
              <li className="privacy-policy__text">
                Общество и иные лица, получившие доступ к персональным данным,
                обязаны не раскрывать третьим лицам и не распространять
                персональные данные без согласия субъекта персональных данных.
              </li>
            </ol>
          </li>
          <li className="privacy-policy__subtitle">
            Предоставление персональных данных
            <ol className="subsequence">
              <li className="privacy-policy__text">
                Под предоставлением персональных данных Абонента понимаются
                действия, направленные на раскрытие персональных данных
                определенному лицу или определенному кругу лиц.
              </li>
              <li className="privacy-policy__text">
                Предоставление персональных данных Абонента осуществляется
                Обществом исключительно для достижения целей, определенных
                письменными договорами между Абонентом и Обществом.
              </li>
              <li className="privacy-policy__text">
                Предоставление персональных данных конкретного Абонента
                осуществляется при обращении Абонента в Общество за технической
                поддержкой, при информировании Абонента об акциях и мероприятиях
                Общества.
              </li>
            </ol>
          </li>
          <li className="privacy-policy__subtitle">
            Защита персональных данных
            <ol className="subsequence">
              <li className="privacy-policy__text">
                Общество обязано при обработке персональных данных Абонентов
                принимать необходимые организационные и технические меры для
                защиты персональных данных от неправомерного или
                доступа к ним, уничтожения, изменения, блокирования, копирования,
                распространения персональных данных, а также от иных неправомерных
                действий.
              </li>
              <li className="privacy-policy__text">
                Защита персональных данных Абонента осуществляется за счет
                Общества в порядке, установленном федеральным законом РФ.
              </li>
              <li className="privacy-policy__text">
                Общую организацию защиты персональных данных Абонентов
                осуществляет Директор общества.
              </li>
              <li className="privacy-policy__text">
                Доступ к персональным данным Абонента имеют сотрудники Общества,
                которым персональные данные необходимы в связи с исполнением ими
                трудовых обязанностей.
              </li>
              <li className="privacy-policy__text">
                Сотрудники структурных подразделений, уполномоченные на ведение
                базы данных, хранение персональных данных в бумажном и (или)
                электронном виде привлекаются в соответствии с законодательством
                Российской Федерации к дисциплинарной и иной ответственности за
                разглашение сведений, содержащихся в указанных источниках
                информации.
              </li>
              <li className="privacy-policy__text">
                Перечень сотрудников Общества, имеющих доступ к персональным
                данным Абонентов, определяется приказом Директора Общества.
              </li>
              <li className="privacy-policy__text">
                Лица, допущенные к работе с персональными данными, обязаны:
                <ul className="dash">
                  <li className="privacy-policy__text">
                    знать законодательство Российской Федерации и нормативные
                    документы Общества по защите персональных данных;
                  </li>
                  <li className="privacy-policy__text">
                    обеспечивать сохранность закрепленного массива носителей с
                    персональными данными, исключать возможность ознакомления с
                    ними других лиц;
                  </li>
                  <li className="privacy-policy__text">
                    докладывать своему непосредственному руководителю обо всех
                    фактах и попытках несанкционированного доступа к персональным
                    данным и других нарушениях.
                  </li>
                </ul>
              </li>
            </ol>
          </li>
          <li className="privacy-policy__subtitle">
            Уничтожение (обезличивание) персональных данных
            <ol className="subsequence">
              <li className="privacy-policy__text">
                Уничтожение (обезличивание) персональных данных Субъекта
                производится в следующих случаях:
                <ul className="dash">
                  <li className="privacy-policy__text">
                    по достижении целей их обработки или в случае утраты
                    необходимости в их достижении в срок, не превышающий тридцати
                    дней с момента достижения цели обработки персональных
                    данных, если иное не предусмотрено договором.
                  </li>
                  <li className="privacy-policy__text">
                    в случае выявления неправомерной обработки персональных данных
                    Обществом в срок, не превышающий десяти дней с момента
                    выявления неправомерной обработки персональных данных.
                  </li>
                  <li className="privacy-policy__text">в случае отзыва Субъектом
                    персональных данных, согласия на обработку его персональных
                    данных, если сохранение персональных данных более не требует
                    для целей обработки персональных данных, в срок, не
                    превышающий тридцати дней с даты поступление указанного
                    отзыва, если иное не предусмотрено договором.
                  </li>
                  <li className="privacy-policy__text">
                    в случае истечения срока хранения персональных данных,
                    определенного в соответствии с законодательством РФ и
                    организационно-распределительными документами Общества.
                  </li>
                  <li className="privacy-policy__text">
                    в случае предписания уполномоченного органа по защите прав
                    субъектов персональных данных, Прокуратуры России или решения
                    суда.
                  </li>
                </ul>
              </li>
            </ol>
          </li>
          <li className="privacy-policy__subtitle">
            Права и обязанности Абонента
            <ol className="subsequence">
              <li className="privacy-policy__text">
                Абонент обязан передавать Обществу или его представителю комплекс
                достоверных, документированных персональных данных, состав которых
                установлен настоящим Положением и договорными обязательствами,
                взятыми на себя сторонами по договору на оказание услуг связи.
              </li>
              <li className="privacy-policy__text">
                Абонент должен сообщать Обществу об изменении своих персональных
                данных
              </li>
              <li className="privacy-policy__text">
                Абонент имеет право требовать исключить или исправить его неверные
                или неполные персональные данные, а также данные, обработанные с
                нарушением требований федерального законодательства.
              </li>
              <li className="privacy-policy__text">
                Абонент имеет право обжаловать в суде любые неправомерные действия
                или бездействие при обработке и защите его персональных данных.
              </li>
            </ol>
          </li>
          <li className="privacy-policy__subtitle">
            Права и обязанности лиц допущенных к обработке персональных данных
            <ol className="subsequence">
              <li className="privacy-policy__text">
                Работники Общества, допущенные к обработке персональных данных,
                обязаны:
                <ul className="dash">
                  <li className="privacy-policy__text">
                    ознакомиться с настоящим положением;
                  </li>
                  <li className="privacy-policy__text">
                    подписать согласие-обязательство о неразглашении персональных
                    данных;
                  </li>
                  <li className="privacy-policy__text">
                    осуществлять обработку только в установленных целях;
                  </li>
                  <li className="privacy-policy__text">
                    получать персональные данные с Абонентов в установленном порядке
                  </li>
                </ul>
              </li>
              <li className="privacy-policy__text">
                Работники Общества, допущенные к обработке персональных данных,
                имеют права:
                <ul className="dash">
                  <li className="privacy-policy__text">
                    отстаивать свои интересы в суде.
                  </li>
                  <li className="privacy-policy__text">
                    предоставлять персональные данные Абонентов третьим лицам,
                    если это предусмотрено договором.
                  </li>
                  <li className="privacy-policy__text">
                    использовать персональные данные Абонента без его согласия,
                    в случаях предусмотренных законодательством РФ.
                  </li>
                </ul>
              </li>
            </ol>
          </li>
          <li className="privacy-policy__subtitle">
            Заключительные положения
            <ol className="subsequence">
              <li className="privacy-policy__text">
                Права и обязанности Общества, как Оператора персональных данных,
                определяются законодательством Российской Федерации в области
                персональных данных. Приложение № 1 Согласие-обязательство о
                неразглашении персональных данных.
              </li>
            </ol>
          </li>
        </ol>

        <section className="privacy-policy__application">
        <span className="privacy-policy__application--name">
          Приложение №1<br />
          к Положению «Политика в отношении обработки персональных данных»
        </span>
          <h2 className="privacy-policy__subtitle">
            Согласие-обязательство о неразглашении персональных данных
          </h2>
          <p className="privacy-policy__text privacy-policy__application--paragraph">
            Я, _______________________________________________________________________,
            паспорт серии ________, номер ____________, выданный « _____ »____________20____ г.,
            __________________________________________________________, в соответствии с
            трудовым договором, должностной инструкцией понимаю, что получаю доступ к
            персональным данным физических юридических лиц, а именно Абонентов.
          </p>
          <p className="privacy-policy__text privacy-policy__application--paragraph">
            Я также понимаю, что во время исполнения своих обязанностей мне приходится
            заниматься сбором, обработкой, накоплением, хранением и т.д. персональных данных
            физических и юридических лиц
          </p>
          <p className="privacy-policy__text privacy-policy__application--paragraph">
            Я обязуюсь хранить в тайне известные мне конфиденциальные сведения,
            информировать директора о фактах нарушения порядка обращения с конфиденциальными
            сведениями, о ставших мне известным попытках несанкционированного доступа к
            информации.
          </p>
          <p className="privacy-policy__text privacy-policy__application--paragraph">
            Я обязуюсь соблюдать правила пользования документами, порядок их учета и
            хранения, обеспечивать в процессе работы сохранность информации, содержащейся в них,
            от посторонних лиц, знакомиться только с теми служебными документами, к которым
            получаю доступ в силу исполнения своих служебных обязанностей.
          </p>
          <p className="privacy-policy__text privacy-policy__application--paragraph">
            Я понимаю, что разглашение такого рода информации может нанести ущерб
            физическим и юридическим лицам, как прямой, так и косвенный.
          </p>
          <p className="privacy-policy__text privacy-policy__application--paragraph">
            В связи с этим даю обязательство при работе (сборе, обработке, накоплении, хранении
            и т.д.) с персональными данными физических и юридических лиц соблюдать все описанные
            в Федеральном законе от 27.07.2006 г. № 152-ФЗ «О персональных данных».
          </p>
          <p className="privacy-policy__text privacy-policy__application--paragraph">
            Я предупрежден(а) о том, что в случае разглашения мной сведений, касающихся
            персональных данных физических лиц, или их утраты я несу ответственность в соответствии
            с действующим законодательством РФ.<br />
            « _____ » ______________________ 20____ г.
          </p>
          <div className="privacy-policy__application--footer">
            <div>
              <span>_______________________________</span>
              <span>________________________________________________</span>
            </div>
            <div>
              <span>Подпись</span>
              <span>Расшифровка подписи</span>
            </div>
          </div>
          <p className="privacy-policy__text">
            Я подтверждаю, что не имею права разглашать сведения:
            <ul className="dash">
              <li className="privacy-policy__text">Фамилия, имя, отчество.</li>
              <li className="privacy-policy__text">Год рождения.</li>
              <li className="privacy-policy__text">Месяц рождения.</li>
              <li className="privacy-policy__text">Дата рождения.</li>
              <li className="privacy-policy__text">Место рождения.</li>
              <li className="privacy-policy__text">Паспортные данные.</li>
              <li className="privacy-policy__text">Адрес электронной почты.</li>
              <li className="privacy-policy__text">
                Номер телефона (домашний, сотовый).
              </li>
              <li className="privacy-policy__text">Заявления от Абонентов.</li>
              <li className="privacy-policy__text">
                Копия документов, удостоверяющих личность, а также иных
                документов, предоставляемых Абонентом, и содержащих персональные
                данные.
              </li>
              <li className="privacy-policy__text">
                Данные по оплатам, содержащие платежные и иные реквизиты Абонента.
              </li>
            </ul>
          </p>
        </section>
      </main>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
