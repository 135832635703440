import { FC, MouseEvent } from "react";
import payment from "../../assets/docs/Payment.pdf";
import storeLt from "../../assets/images/app-store.svg";
import playLt from "../../assets/images/play-market.svg";
import "./Footer.scss"
import { Link } from "react-router-dom";

const Footer: FC = () => {
  const anchorHandler = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    const target = e.target as HTMLAnchorElement;
    const href = target.getAttribute("href");
    if (href) {
      document.querySelector(href)!.scrollIntoView({
        behavior: "smooth"
      });
    }
  };

  return (
    <div className="footer">
      <div className="footer__block">
        <div className="footer__column">
          <Link to={"/"} className="footer__link">Клиентам</Link>
          <a className="footer__link footer__item--last" href={"https://prm.reforce.group/"}
             target={"_blank"}>Партнерам</a>
          <Link to="/career" className="footer__link">Карьера</Link>
          {/*<a href={privacy} target={"_blank"} className="footer__doc">Политика конфиденциальности</a>*/}
          {/*<a href={""} className="footer__doc">Пользовательское соглашение</a>*/}
          <Link to="/privacy" className="footer__link">Политика конфиденциальности</Link>
          <a href={payment} target={"_blank"} className="footer__doc">Платёжная информация</a>
        </div>
        <div className="footer__column">
          {/*<div className="footer__contact location">г. Екатеринбург, пер. Автоматики, стр. 1, помещ. 11, офис 213*/}
          {/*</div>*/}
          <div className="footer__contact phone"><a href="tel:88005557555">8 800 555-75-55</a></div>
          <div className="footer__contact footer__item--last mail"><a
            href="mailto:info@reforce.group">info@reforce.group</a></div>
          <div className="footer__app">Приложения:
            <div className="app-badges">
              <img src={storeLt} alt="app store" />
              <img src={playLt} alt="google play" />
            </div>
          </div>
        </div>
      </div>
      <div className="divider"></div>
      <div className="copyright">2023{new Date().getFullYear() === 2023 ? "" : " - " + new Date().getFullYear()} ООО
        “Рефорс”. Все права защищены
      </div>
    </div>
  );
};
export default Footer;
