import { FC, useState } from "react";
import "./Dialog.scss";

interface DialogProps {
  title: string;
  message?: string;
  isOpen: boolean;
  onClose?: () => void;
}

const Dialog: FC<DialogProps> = ({ title, message, isOpen, onClose }) => {

  const closeDialog = () => {
    if (onClose)
      setTimeout(() => {
        onClose();
      }, 100);
  };

  return (
    <div className={isOpen ? "dialog open" : "dialog"}>
      <div className="dialog-overlay" onClick={closeDialog}></div>
      <div className="dialog-content">
        <div className="dialog-title">{title}</div>
        {message && (<div className="dialog-body">
          {message}
        </div>)}
        <div className="dialog-footer">
          <button className="button button--mini button--red" onClick={closeDialog}>ок</button>
        </div>
      </div>
    </div>
  );
};

export default Dialog;
