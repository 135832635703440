import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/images/logotype.svg";
import "./Login.scss";

export default function Login(): JSX.Element {
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Личный кабинет Reforce - вход";
  });

  function onClick() {
    return () => {
      navigate("/");
    };
  }

  return (

    <div className="welcome-page">
      <div className="welcome-page__content">
        <div className="welcome-page__logo">
{/*          <img
            className="logo-image"
            src={logo}
            alt="logotype"
          />*/}
          <div className="logo">
            <span className="logo__title">Reforce</span>
            <span className="logo__subtitle">Личный кабинет</span>
          </div>
        </div>
        <div className="welcome-page__form">
          <div className="login-header">
            <h1 className="login-header__title">
              Добро пожаловать!
            </h1>
          </div>
          <form className="login-form" onSubmit={(e) => e.preventDefault()}>
            <div className="input">
              <label htmlFor="phone" className="input__label">Телефон</label>
              <input
                className="login-form__input"
                type="tel"
                id="phone"
                placeholder="+7 999 123-00-00"
                autoComplete="tel-national"
                required
              />
            </div>
            <button
              className="login-form__btn"
              type={"submit"}
            >
              Войти
            </button>
          </form>
        </div>
      </div>
      <div className="welcome-page__decor">
        <div className="blocquote">
      <span
      >Высокотехнологичные и понятные услуги безопасности, объединенные в
        рамках одного удобного приложения</span
      >
        </div>
      </div>
    </div>

  )
    ;
}
